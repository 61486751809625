import './analytics'
import 'intersection-observer'
import 'autotrack'
import 'autotrack/lib/plugins/event-tracker'
import 'autotrack/lib/plugins/outbound-link-tracker'
import 'autotrack/lib/plugins/page-visibility-tracker'

ga('create', 'UA-121358240-1', 'auto')
ga('require', 'outboundLinkTracker', {
  events: ['click', 'auxclick', 'contextmenu'],
  fieldsObj: {
    eventCategory: 'External links',
    eventAction: 'click'
  }
})
ga('require', 'eventTracker')
ga('require', 'pageVisibilityTracker')
ga('send', 'pageview')

setTimeout(function () {
  const yesTitleEl = $('.result-section.result-section--yes .result-section__service__title')
  const maybeTitleEl = $('.result-section.result-section--maybe .result-section__service__title')

  // Push yes services
  for (const el of yesTitleEl) {
    const serviceTitle = $(el).text()

    ga('send', {
      hitType: 'event',
      eventCategory: 'Viewport (Yes)',
      eventAction: 'impression',
      eventLabel: serviceTitle
    })
  }

  // Push maybe services
  for (const el of maybeTitleEl) {
    const serviceTitle = $(el).text()

    ga('send', {
      hitType: 'event',
      eventCategory: 'Viewport (Maybe)',
      eventAction: 'impression',
      eventLabel: serviceTitle
    })
  }
}, 500)
